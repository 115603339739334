@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Prompt:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://cdn.plyr.io/3.5.2/plyr.css');
@import './quill.snow.css';
@import './react-rating.scss';
// $theme-colors: (
//     "primary": #3182ce,
//     "success": #2f855a
// );
// @import "~bootstrap/scss/bootstrap";


*{
    font-family: 'Prompt', sans-serif;
    /* Keyword values */
user-select: none;
/* Mozilla-specific values */
-moz-user-select: none;
/* WebKit-specific values */
-webkit-user-select: none;
/* Microsoft-specific values */
-ms-user-select: none;
}

.hide-toolbar .ql-toolbar.ql-snow{
  display: none;
}
.noMinHeight .ql-editor{
  min-height: 100%;
}

.noBorder .ql-container.ql-snow{
  border: none;
}

.DropClick {
    float: right;
    right: 0;
    margin-top: -35px;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    height: 30px;
  }
  .text2line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
 }

 // Background of Main BOx
 .react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  // background: teal;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

// Set Color of Tag
.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  // background: #F1F1F1;
  background: white;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: inherit;
  color:black;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
  position: absolute;
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: pink;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #F4D03F ;
}

.react-tags__suggestions li.is-active {
  background: #F4D03F ;
  
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}


