


@media only screen and (max-width: 2400px){
    .responsiveSideBarHeight{
        background-color: #e2e8f0;
        height: calc(100vh - 65px);
    }
}

@media only screen and (max-width: 1200px){
    .responsiveSideBarHeight{
        background-color: #e2e8f0;
        height: calc(100vh - 65px);
    }
}


@media only screen and (max-width: 760px){
    .responsiveSideBarHeight{
        background-color: #e2e8f0;
        height: calc(100vh - 65px);
    }
}

@media only screen and (max-width: 600px){
    .responsiveSideBarHeight{
        background-color: #e2e8f0;
        height: calc(100vh - 65px);
    }
}
